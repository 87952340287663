










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar
} from 'bootstrap-vue'
import { Getter } from '@/store/decorators'
import AuthModule from '@/store/modules/authModule'
import { User } from '@/models/User'

@Component({
  name: 'AdminDashboard',
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  }
})
export default class AdminDashboard extends Vue {
  @Getter(AuthModule, 'activeUser') private activeUser!: User
}
